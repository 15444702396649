<template>
  <div class="px-4 py-3 flex items-center justify-between bg-[#202126]">
    <router-link to="/">
      <img class="w-4 opacity-50" src="@/assets/icons/back.svg" alt="icon" />
    </router-link>
    <img src="@/assets/logo.svg" alt="logo" />

    <router-link to="/message">
      <img v-if="+isReadCount" class="w-5" src="@/assets/icons/message.svg" />
      <img v-else class="w-5" src="@/assets/icons/unnewmsg.svg" />
    </router-link>
  </div>
  <div class="pt-4">
    <div class="flex items-center gap-3 px-3 mb-10">
      <div
        @click="
          goToRoom(
            `/game/car/3/${findGameID(180, 'RacingCar10')}`,
            findGameID(180, 'RacingCar10')
          )
        "
        class="relative flex-1"
      >
        <img class="w-full" src="@/assets/images/games/car1.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 pb-2 flex items-center justify-center text-lg"
        >
          赛车 3分
        </div>
        <div
          class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 btn-blue text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
      <div
        @click="
          goToRoom(
            `/game/car/5/${findGameID(300, 'RacingCar10')}`,
            findGameID(300, 'RacingCar10')
          )
        "
        class="relative flex-1"
      >
        <img class="w-full" src="@/assets/images/games/car2.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 pb-2 flex items-center justify-center text-lg"
        >
          赛车 5分
        </div>
        <div
          class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 btn-blue text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
    </div>
    <div class="flex items-center gap-3 px-3 mb-10">
      <div
        @click="
          goToRoom(
            `/game/boat/3/${findGameID(180, 'RacingBoat10')}`,
            findGameID(180, 'RacingBoat10')
          )
        "
        class="relative flex-1"
      >
        <img class="w-full" src="@/assets/images/games/boat1.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 pb-2 flex items-center justify-center text-lg"
        >
          赛船 3分
        </div>
        <div
          class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 btn-green text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
      <div
        @click="
          goToRoom(
            `/game/boat/5/${findGameID(300, 'RacingBoat10')}`,
            findGameID(300, 'RacingBoat10')
          )
        "
        class="relative flex-1"
      >
        <img class="w-full" src="@/assets/images/games/boat2.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 pb-2 flex items-center justify-center text-lg"
        >
          赛船 5分
        </div>
        <div
          class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 btn-green text-xs rounded-full w-[fit-content] px-3 py-[6px] float-right"
        >
          立即开始
        </div>
      </div>
    </div>
    <div class="flex items-center gap-3 px-3 mb-5">
      <div class="relative flex-1">
        <img class="w-full" src="@/assets/images/games/soom1.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 flex items-center justify-center text-lg"
        >
          敬请期待
        </div>
      </div>
      <div class="relative flex-1">
        <img class="w-full" src="@/assets/images/games/soom2.png" alt="image" />
        <div
          class="absolute bg-black bg-opacity-40 bottom-0 w-full h-16 flex items-center justify-center text-lg"
        >
          敬请期待
        </div>
      </div>
    </div>
    <div
      class="bg-[#202126] flex flex-col items-center justify-center gap-4 h-48 px-4"
    >
      <p class="text-xl">合作厂商</p>
      <img class="w-full" src="@/assets/images/friend.png" alt="images" />
    </div>
    <p class="bg-[#111111] text-sm text-center py-4">
      © New Pu Jung All Rights Reserved
    </p>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { showFailToast } from 'vant';

const router = useRouter();
const store = useStore();
const gameList = computed(() => store.state.gameList);
const userInfo = computed(() => store.state.userInfo);
const isReadCount = computed(() => {
  return store.state.unread_count?.sys_unread;
});
const goToRoom = (url, id) => {
  // const item = gameList.value.find((e) => e.id === id);
  // if (+userInfo.value.last_point < +item.enter_min) {
  //   showFailToast(`余额需大于\n${+item.enter_min}`);
  //   return;
  // }
  router.push(url);
};
const findGameID = (second, gameType) => {
  const item = gameList.value.find(
    (e) => e.game_type === gameType && +e.seconds === +second
  );
  if (item) {
    return item.id;
  } else {
    return null;
  }
};
</script>
<style scoped>
.line {
  background: linear-gradient(180deg, #ff2a6d, #ff2aaf);
}

.btn-pink {
  background: linear-gradient(90deg, #ff2a6d, #ff2aaf);
  box-shadow: 0 0 15px rgba(255, 42, 175, 0.8);
}

.btn-blue {
  background: linear-gradient(90deg, #1da3cd, #25bae9);
  box-shadow: 0 0 15px rgba(21, 186, 236, 0.9);
}

.btn-green {
  background: linear-gradient(90deg, #0e980e, #30b91e);
  box-shadow: 0 0 15px rgba(51, 202, 31, 0.8);
}
</style>
